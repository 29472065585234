@tailwind base;
@tailwind components;
@tailwind utilities;

/* 自定義樣式 */
body {
  font-family: 'Helvetica', 'Arial', sans-serif;
  background-color: #1a202c; /* dark background */
  color: #edf2f7; /* light text */
}
/*
nav {
  background-color: #ff6347; 
}
*/

nav a:hover {
  text-decoration: underline;
}

.container {
  max-width: 1200px;
  padding: 0 1rem; /* 增加移動設備的邊距 */
}

.book-card {
  transition: transform 0.3s;
}

.book-card:hover {
  transform: scale(1.05);
}

.pagination button {
  transition: background-color 0.3s, color 0.3s;
}

.pagination button:hover {
  background-color: #007bff;
  color: white;
}

/* 手機樣式調整 */
@media (max-width: 768px) {
  .text-5xl {
    font-size: 2.5rem;
  }

  .text-3xl {
    font-size: 1.75rem;
  }

  .container {
    padding: 0 0.5rem;
  }

  .grid-cols-1 {
    grid-template-columns: repeat(1, 1fr);
  }

  .grid-cols-2 {
    grid-template-columns: repeat(1, 1fr);
  }

  .grid-cols-3 {
    grid-template-columns: repeat(1, 1fr);
  }
}

@import '~react-pdf/dist/esm/Page/AnnotationLayer.css';
@import '~react-pdf/dist/esm/Page/TextLayer.css';

html, body {
  overflow-x: hidden;
  width: 100vw;
  max-width: 100vw;
}

/* 避免 iOS Safari 無法全螢幕的情況 */
.fullscreen-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: black;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.flipbook-container {
  max-width: 100vw;  /* 限制最大寬度為視窗寬度 */
  max-height: 100vh; /* 限制最大高度為視窗高度 */
  overflow: hidden;  /* 防止超出 */
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
