.full-image {
  width: 100%;
  height: auto;
  position: relative;
  display: block;
  margin: 0 auto;
}

.image-container {
  position: relative;
  width: 80%;
  max-width: 100%;
  margin: 0 auto;
}

.image-section {
  position: absolute;
  width: 50%;
  height: 50%;
  border: 4px solid transparent;
  box-sizing: border-box;
  cursor: pointer;
}

.image-section.selected {
  border-color: orange;
}

.checkmark {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  background-color: white;
  border-radius: 50%;
  border: 2px solid orange;
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkmark svg {
  width: 20px;
  height: 20px;
  color: orange;
}